@media (max-width: 1024px) {
	.in-page-ad {
		display: none;
	}
}
.in-page-ad {
	text-align: center;
	z-index: 0;
}
.in-page-ad .leaderboard {
	margin: auto;
}
