.three-up{
    position: relative;
    width: 103%;
    left: -1.5%;

    li {
        width: 30.33333%;
        margin-left: 1.5%;
        margin-right: 1.5%;
        display: inline-block;
        vertical-align: top;
    }

}

[dir="rtl"]{
	.three-up{
	    left: 0;
	    right: -1.5%;
	}
}

@media (max-width:767px){
	.three-up{
	    position: relative;
	    width: 100%;
	    left: 0;

	    li {
	        width: 100%;
	        margin-left: 0;
	        margin-right: 0;
	    }

	}

	[dir="rtl"]{
		.three-up{
		    right: 0;
		}
	}


}