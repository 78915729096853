.featured {
  .wrapper {
    border-radius: 45px;
    padding: $padding * 4.5 $padding 0 $padding;

    @media (max-width: 767px) {
      border-radius: 35px;
    }
  }
  > .wrapper > .ribbon {
    top: 20px;
  }
  .featured_wrapper {
    text-align: center;
    font-size: 0;
    direction: ltr;
  }
  .featured_item {
    position: relative;
    width: 32%;
    margin-left: 1.5%;
    margin-bottom: 1.5%;
    display: inline-block;
    vertical-align: middle;

    &:nth-child(3n + 1) {
      margin-left: 0;
    }
  }
  &.featured-size-4,
  &.featured-size-2 {
    .featured_item {
      width: 49.25%;

      &:nth-child(3n + 1) {
        margin-left: 1.5%;
      }
      &:nth-child(2n + 1) {
        margin-left: 0;
      }
    }
  }
}

.featured_target--large-mpu {
  @media (min-width: 768px) and (max-width: 900px) {
    .featured_item {
      width: 26.5%;
    }
    .featured_item.mpu {
      width: 44%;
    }
  }

  @media (min-width: 901px) and (max-width: 992px) {
    .featured_item {
      width: 30%;
    }
    .featured_item.mpu {
      width: 37%;
    }
  }
}

@media (max-width: 767px) {
  .featured {
    .wrapper {
      padding: $padding * 3 math.div($padding, 2) math.div($padding, 2) math.div($padding, 2);
    }

    > .wrapper > .ribbon {
      top: 10px;
    }

    .featured_item {
      width: 100% !important;
      max-width: none !important;
      margin-left: 0 !important;
      margin-bottom: 10px !important;
    }
  }
}
