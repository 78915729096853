
.label-new {
  position: absolute;
  top: 0;
}
.label-new span {
  position: absolute;
  z-index: 2;
  top: 5px;
  left: 5px;
  font-size: 2.2rem;
}

.label-new:before {
  content: '';
  display: block;
  position: absolute;
  z-index: 1;
  width: 0;
  height: 0;
  border-style: solid;
  border-top-width: 55px;
  border-right-width: 115px;
  border-bottom-width: 0px;
  border-left-width: 0px;
  border-color: #e62c91 transparent transparent transparent;
}

.videoendcard .label-new:before{
    border-top-width: 55px;
    border-right-width: 84px;
}