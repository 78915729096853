$edgeHang: 15px;
$ribbonPadding: 8px;

.ribbon {
  background-color: $color_orange;
  border-top-right-radius: 15px;
  border-bottom-right-radius: 15px;
  border-top-left-radius: 15px;
  color: $color_purple;
  left: -10px;
  line-height: 1;
  padding: $ribbonPadding;
  padding-right: $ribbonPadding + $edgeHang;
  padding-left: $ribbonPadding + $edgeHang;
  position: absolute;
  top: 10px;
  z-index: 1;

  @include fontp();
  @include fontsize(22);

  &:after {
    content: '';
    position: absolute;
    display: block;
    width: 10px;
    height: 14px;
    background-color: #400a36;
    bottom: -14px;
    left: 0;
    border-bottom-left-radius: 10px;
    border-top-left-radius: 10px;
  }

  &:before {
    width: 10px;
    height: 90%;
    padding: 0 0 0.438em;
    top: 5px;
    left: 0;
    background: inherit;
    border-top-left-radius: 15px;
    border-bottom-left-radius: 15px;
    content: '';
    position: absolute;
    display: block;
  }

  &.ribbon-large {
    line-height: 1;

    @include fontsize(32);

    &:after {
      border-width: 47px 45px 0 0;
    }
  }
}

[dir='rtl'] .ribbon {
  left: auto;
  right: -$edgeHang;

  @include transform(scale(-1, 1));

  h2,
  span {
    display: block;
    @include transform(scale(-1, 1));
  }
}

@media (max-width: 767px) {
  $edgeHang: 8px;
  $ribbonPadding: 5px;

  .ribbon {
    left: -$edgeHang;
    line-height: 1;

    @include fontsize(13);

    &:before {
      border-width: $edgeHang 0 0 $edgeHang;
      top: 8px;
    }

    &:after {
      border-width: 25px 23px 0 0;
      bottom: -11px;
      height: 11px;
    }

    &.ribbon-large {
      line-height: 1;

      @include fontsize(22);

      &:after {
        border-width: 41px 30px 0 0;
        bottom: -14px;
        height: 14px;
      }
    }
  }

  [dir='rtl'] .ribbon {
    left: auto;
    right: -$edgeHang;
  }
}
