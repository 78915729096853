.cookie_popup {
  width: 100%;
  z-index: 999999;
  position: fixed;
  bottom: 0;
  padding: 1.5% 1%;
  background-color: $color_purple;
  color: #ffffff;
  box-shadow: 0px 0px 17px rgba(0, 0, 0, 0.2);

  .inner {
    max-width: 1024px;
    width: 100%;
    margin: 0 auto;
  }

  .text {
    width: 55%;
    display: inline-block;
    vertical-align: middle;
    padding: 0 5%;

    @media (min-width: 500px) {
      width: 65%;
    }
  }

  .buttons {
    width: 40%;
    display: inline-block;
    vertical-align: middle;
    text-align: center;

    @media (min-width: 500px) {
      width: 30%;
    }
  }

  a {
    background-color: $color_yellow;
    border-radius: 10px;
    color: $color_purple;
    padding: 10px 15px 5px;
    font-size: 1.4rem;
    line-height: 1.1;
    display: inline-block;
    margin: 2px 4px;

    @include fontp();
  }

  h5 {
    font-size: 1.5rem;
  }

  p {
    font-size: 1.3rem;
  }
}

.no-touchevents .cookie_popup a:hover {
  background-color: $color_blue;
}

[dir='rtl'] .cookie_popup a {
  direction: rtl;
}
