.pre {
  position: relative;
  display: block;
  white-space: pre-wrap;
}

.menu {
  margin-bottom: 0;
}

.menu .flyout {
  display: none;
  float: left;
  width: 100%;
  z-index: 40;
  background: #43b8e7;
  padding: 5% 5% 5% 10%;
  margin-bottom: 5%;
}

//.sort {
//  float: left;
//  width: 100%;
//  margin-bottom: 3%;
//  cursor: pointer;
//
//  @include fontp();
//}
//
//.sort > a {
//  background-color: $color_yellow;
//  border-radius: 20px;
//  color: $color_purple;
//  display: block;
//  float: left;
//  font-size: 2.3rem;
//  position: relative;
//  padding: 10px;
//  text-align: center;
//  text-decoration: none;
//  width: 100%;
//  z-index: 10;
//
//  @include fontsize(20);
//
//  &:after {
//    background-repeat: no-repeat;
//    background-position: center;
//    background-size: 45%;
//    content: '';
//    height: 45px;
//    right: 0;
//    position: absolute;
//    top: 0;
//    width: 45px;
//
//    -ms-transform: rotate(0deg);
//    -webkit-transform: rotate(0deg);
//    transform: rotate(0deg);
//
//    -webkit-transition: -webkit-transform 0.3s ease-in-out;
//    -ms-transition: -ms-transform 0.3s ease-in-out;
//    transition: transform 0.3s ease-in-out;
//
//    @include backgroundimage('sort_arrow_default.svg');
//  }
//}
//
//.sort--games a {
//  background-color: $color_blue;
//  &:after {
//    @include backgroundimage('sort_arrow.svg');
//  }
//}
//
//.sort--videos a {
//  background-color: $color_orange;
//  &:after {
//    @include backgroundimage('sort_arrow.svg');
//  }
//}
//
//.sort--series a {
//  background-color: $color_green;
//  &:after {
//    @include backgroundimage('sort_arrow.svg');
//  }
//}
//
//html[dir='rtl'] .sort a {
//  background-position: 5% center;
//  float: right;
//}
//
//.sort ul {
//  border-bottom: {
//    right-radius: 20px;
//    left-radius: 20px;
//  }
//  display: none;
//  float: left;
//  width: 100%;
//  background: #ffffff;
//  padding: 2%;
//  text-align: center;
//}
//
//.sort ul li {
//  cursor: pointer;
//  padding: 5px 0;
//}
//
//.sort.active ul {
//  display: block;
//}
//
//.sort.active > a:after {
//  -webkit-transform: rotate(180deg);
//  transform: rotate(180deg);
//}
//
//.sort li a {
//  background: none !important;
//  padding: 1%;
//}
//
//.sort li a:active,
//.sort li a:focus,
//.sort li a.active {
//  color: #000000;
//}
//
//.cube_bullet {
//  display: none;
//  width: 22px;
//  position: absolute;
//  bottom: 0px;
//  left: 8px;
//}

/* Media queries
------------------------------------------------------------------------------- */

//@media all and (min-width: 480px) {
//  .sort {
//    position: relative;
//    float: right;
//    width: 200px;
//  }
//
//  html[dir='rtl'] .sort {
//    float: left;
//  }
//
//  .sort ul {
//    position: absolute;
//    top: 100%;
//    z-index: 40;
//    padding-top: 30px;
//    margin-top: -20px;
//    z-index: 1;
//  }
//
//  .sort ul a {
//    padding: 5px 0;
//
//    @include fontsize(20);
//  }
//}

@media all and (min-width: 580px) {
  .menu .flyout {
    position: absolute;
    top: 89%;
    padding: 5%;
    margin-top: 0 !important;
    margin-bottom: 0;
  }
}

//@media all and (min-width: 768px) {
//  .sort {
//    float: none;
//    position: absolute;
//    top: 20px;
//    right: 20px;
//  }
//
//  html[dir='rtl'] .sort {
//    right: auto;
//    left: 20px;
//  }
//}

//.all-videos-list,
.all-games-list {
  direction: ltr;
}

/* #google_ads_iframe_/8494/turner/cartoonnetwork.web.site/mobilehome/roc/mban.320x50_0__container__ { margin: 0 auto; }
*/
#div-gpt-ad-1402421516953-0 > div {
  margin: 0 auto;
}

#ima-ad-container > div:first-child {
  width: 100% !important;
  height: 100% !important;
}

#ima-seek-bar-div {
  width: 100% !important;
}

.tablet#gameplay_page_iframe,
.smartphone#gameplay_page_iframe {
  position: absolute;
  top: 0;
  left: 0;
}

#adContainer {
  margin: 0 auto;
  display: block;
}

// Old game iframe styling
//.gameiframewrapper {
//  position: absolute;
//  top: 0;
//  left: 0;
//  height: 100%;
//  width: 100%;
//  height: 1px !important; /*ios bug fix*/
//  width: 1px !important; /*ios bug fix*/
//  min-height: 100%; /*ios bug fix*/
//  min-width: 100%; /*ios bug fix*/
//  z-index: 1; /*overflow: auto;*/
//  overflow: auto;
//  -webkit-overflow-scrolling: touch;
//}
//
//.gameiframewrapper > iframe {
//  position: absolute;
//  top: 0;
//  left: 0;
//  height: 100%;
//  width: 100%;
//  height: 1px !important; /*ios bug fix*/
//  width: 1px !important; /*ios bug fix*/
//  min-height: 100%; /*ios bug fix*/
//  min-width: 100%; /*ios bug fix*/
//  z-index: 1;
//}

video {
  z-index: 0 !important;
}
.vjs-control-bar {
  display: none;
}
.kaltura-video {
  position: relative;
  padding-bottom: 56.25%; /* 16:9 */
  padding-top: 25px;
  height: 0;
}
.kaltura-video iframe {
  position: absolute;
  top: 0;
  left: 0;
}
