.like_button {
  display: inline-block;
  vertical-align: top;
  @include noselect();
  @include fontsize(26);
  line-height: 1;
  cursor: pointer;
  background-color: transparent;
  margin-bottom: 5px;
  direction: ltr;
  border-radius: 24px;

  -webkit-transition: background-color 0.3s;
  transition: background-color 0.3s;

  &--hidden {
    display: none;
  }

  .icon_container,
  .count {
    position: relative;
    display: inline-block;
    vertical-align: middle;
  }
  .icon_container {
    width: 48px;
    height: 48px;

    @media screen and (min-width: 768px) and (max-width: 945px) {
      height: 44px;
    }

    .icon {
      position: absolute;
      top: 50%;
      left: 50%;
      width: 40px;
      -webkit-transition: -webkit-transform 0.3s;
      transition: transform 0.3s;
      &.small {
        width: 30px;
      }
    }
    .icon_tick {
      @include translatecenter(scale(0));
    }
    .icon_thumbs {
      @include translatecenter(scale(1));
      //@include animation('bounceAnimLoopCenter 3s infinite');
      @include backface();
    }
    //&.small {
    //	width: 44px;
    //	}
  }
  .count {
    color: #ffffff;
    padding: {
      top: 12px;
      right: 5px;
      bottom: 12px;
      left: 5px;
    }
    width: 70px;
    text-align: center;
    line-height: 23px;
    font-size: 25px;
    &.small {
      width: 40px;
    }
  }
  &.liked {
    cursor: default;
    .icon_container {
      .icon_tick {
        @include translatecenter(scale(1));
      }
      .icon_thumbs {
        //@include animation('none');
        @include translatecenter(scale(0));
      }
    }
  }
}

.no-touchevents {
}

@media (max-width: 767px) {
  .like_button {
    display: block;
    &.nowrap {
      display: inline-block;
    }
  }
}
