// ****** Button styling
.button {
  cursor: pointer;
  display: inline-block;
  line-height: 1;
  position: relative;
  text: {
    decoration: none;
  }

  @include fontsize(23);
  @include fontb();

  .button_inner {
    background-color: $color_purple;
    border-radius: 25px;
    color: #ffffff;
    display: block;
    padding: {
      top: 15px;
      right: 30px;
      bottom: 15px;
      left: 30px;
    }
    position: relative;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    -webkit-transition: background-color 0.3s;
    transition: background-color 0.3s;

    &.multi_line {
      white-space: normal;
      overflow: visible;
      text-overflow: initial;
    }

    &:hover {
      background-color: $color_purple;
    }
  }

  .button_icon {
    height: 60px;
    position: absolute;
    top: 50%;
    right: 10px;

    -webkit-transition: -webkit-transform 0.3s $superbounce;
    transition: transform 0.3s $superbounce;

    @include translatecentery();
  }

  &.active .button_inner {
    background-color: lighten($color_purple, 8%);
  }

  &.button--small {
    @include fontsize(18);
    line-height: 1;

    .button_inner {
      padding-left: 34px;
      padding-right: 14px;
    }
  }

  &.default-cursor {
    cursor: default;

    .button_icon {
      @include translatecentery(!important);
    }
  }

  &.disabled {
    opacity: 0.2;
    cursor: default;
  }

  &--align-top {
    vertical-align: top;
  }
}

// ******* Button content type variants
.button {
  &--games .button_inner {
    background-color: $color_blue;
  }

  &--videos .button_inner,
  &--comics .button_inner {
    background-color: $color_orange;
  }
}

[dir='rtl'] {
  .button {
    text-align: right;
    direction: rtl;

    .button_icon {
      right: auto;
      left: 10px;
    }

    &.button--small .button_inner {
      padding-left: 14px;
      padding-right: 34px;
    }
  }
}

.b_pink .caption a.button--404,
.b_purple .caption a.button--404 {
  color: white;
  display: inline-block;

  .button_inner {
    background-color: #400a36;
    border-radius: 15px;
    display: block;
    padding: 12px;
    margin-top: 10px;
    @include fontp();
  }
}

// ****** Buttons styling
.buttons {
  font-size: 0;
  direction: ltr;

  .wrapper {
    padding: 0;
  }
  .button {
    width: 50%;
    margin-bottom: 10px;

    &:nth-child(odd) {
      padding-right: 10px;
    }

    &:nth-child(even) {
      padding-left: 10px;
    }
  }

  &.show-buttons .button_inner {
    border: {
      color: #ffffff;
      style: solid;
      width: 4px;
    }
  }
}

// ****** Button Touch styling
.no-touchevents .button:hover {
  .button_icon {
    @include translatecentery(rotate(-10deg));
  }

  .button_inner {
    background-color: $color_purple;
  }
}

// ****** Buttons responsive styling
@media (max-width: 767px) {
  .button {
    line-height: 1;
    margin-bottom: math.div($sectionMargin, 2);

    @include fontsize(math.div(48, 2));

    .button_inner {
      padding-left: 50px;
      padding-right: 50px;

      &:before {
        width: 36px;
        height: 42px;
      }
    }

    .button_icon {
      height: math.div($iconHeight, 2);
    }
  }

  [dir='rtl'] .button .button_inner {
    padding-left: 50px;
    padding-right: 50px;
  }

  .buttons .button {
    width: 100%;

    &:nth-child(odd) {
      padding-right: 0;
    }

    &:nth-child(even) {
      padding-left: 0;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }
}
