.footer {
  background-color: #ffffff;
  display: block;
  margin-top: $sectionMargin;
  padding: {
    top: 20px;
    bottom: 20px;
  }
  position: relative;
  width: 100%;

  @media (min-width: 786px) {
    padding: {
      top: 40px;
      bottom: 40px;
    }

    @include fontsize(18);
  }
}

.footer .footer_navigation {
  margin-bottom: 20px;

  @include fontsize(18);

  @media (max-width: 786px) {
    margin: {
      right: -5px;
      bottom: 40px;
      left: -5px;
    }

    @include fontsize(16);
  }
}

.footer a {
  color: $color_orange;
  display: inline-block;
  margin: {
    right: 60px;
    bottom: $padding;
  }

  @media (max-width: 786px) {
    padding: 5px;
    margin: 0;
    width: 50%;
  }
}

.footer p {
  margin-bottom: 20px;
}

.footer__logos {
  margin-top: 20px;
}

.footer .copyright {
  img {
    display: inline-block;
    height: 100px;
    margin: {
      right: 5px;
      left: 5px;
    }

    @media (min-width: 786px) {
      margin: {
        right: 10px;
        left: 0;
      }
    }
  }
}

@media (max-width: 786px) {
  .footer__logos {
    text-align: center;
  }
}

.no-touchevents .footer a:hover {
  color: darken($color_orange, 18%);
}
