.carousel_wrapper_ignore {
  display: flex;
  flex-wrap: wrap;
}

.carousel_wrapper_ignore .carousel_item {
  width: 100%;
  margin-bottom: 20px;
  @media only screen and (min-width: 550px) {
    width: calc(33.3% - 10px);
    margin: {
      right: 5px;
      left: 5px;
      bottom: 0;
    }
  }
}
