#onetrust-banner-sdk #content,
#onetrust-banner-sdk .checkbox,
#onetrust-banner-sdk .main-content,
#onetrust-banner-sdk .toggle,
#onetrust-banner-sdk a,
#onetrust-banner-sdk button,
#onetrust-banner-sdk div,
#onetrust-banner-sdk h1,
#onetrust-banner-sdk h2,
#onetrust-banner-sdk h3,
#onetrust-banner-sdk h4,
#onetrust-banner-sdk h5,
#onetrust-banner-sdk h6,
#onetrust-banner-sdk img,
#onetrust-banner-sdk input,
#onetrust-banner-sdk label,
#onetrust-banner-sdk li,
#onetrust-banner-sdk nav,
#onetrust-banner-sdk p,
#onetrust-banner-sdk section,
#onetrust-banner-sdk span,
#onetrust-banner-sdk svg,
#onetrust-banner-sdk table,
#onetrust-banner-sdk tbody,
#onetrust-banner-sdk td,
#onetrust-banner-sdk thead,
#onetrust-banner-sdk tr,
#onetrust-banner-sdk ul,
#onetrust-pc-sdk #content,
#onetrust-pc-sdk .checkbox,
#onetrust-pc-sdk .main-content,
#onetrust-pc-sdk .toggle,
#onetrust-pc-sdk a,
#onetrust-pc-sdk button,
#onetrust-pc-sdk div,
#onetrust-pc-sdk h1,
#onetrust-pc-sdk h2,
#onetrust-pc-sdk h3,
#onetrust-pc-sdk h4,
#onetrust-pc-sdk h5,
#onetrust-pc-sdk h6,
#onetrust-pc-sdk img,
#onetrust-pc-sdk input,
#onetrust-pc-sdk label,
#onetrust-pc-sdk li,
#onetrust-pc-sdk nav,
#onetrust-pc-sdk p,
#onetrust-pc-sdk section,
#onetrust-pc-sdk span,
#onetrust-pc-sdk svg,
#onetrust-pc-sdk table,
#onetrust-pc-sdk tbody,
#onetrust-pc-sdk td,
#onetrust-pc-sdk thead,
#onetrust-pc-sdk tr,
#onetrust-pc-sdk ul,
#ot-sdk-cookie-policy #content,
#ot-sdk-cookie-policy .checkbox,
#ot-sdk-cookie-policy .main-content,
#ot-sdk-cookie-policy .toggle,
#ot-sdk-cookie-policy a,
#ot-sdk-cookie-policy button,
#ot-sdk-cookie-policy div,
#ot-sdk-cookie-policy h1,
#ot-sdk-cookie-policy h2,
#ot-sdk-cookie-policy h3,
#ot-sdk-cookie-policy h4,
#ot-sdk-cookie-policy h5,
#ot-sdk-cookie-policy h6,
#ot-sdk-cookie-policy img,
#ot-sdk-cookie-policy input,
#ot-sdk-cookie-policy label,
#ot-sdk-cookie-policy li,
#ot-sdk-cookie-policy nav,
#ot-sdk-cookie-policy p,
#ot-sdk-cookie-policy section,
#ot-sdk-cookie-policy span,
#ot-sdk-cookie-policy svg,
#ot-sdk-cookie-policy table,
#ot-sdk-cookie-policy tbody,
#ot-sdk-cookie-policy td,
#ot-sdk-cookie-policy thead,
#ot-sdk-cookie-policy tr,
#ot-sdk-cookie-policy ul {
  font-size: 15px !important;
  font-family: Arial, sans-serif !important;
}

#onetrust-consent-sdk,
#onetrust-consent-sdk *,
#onetrust-consent-sdk div,
#onetrust-consent-sdk span,
#onetrust-consent-sdk ul,
#onetrust-consent-sdk li,
#onetrust-consent-sdk a,
#onetrust-consent-sdk p,
.optanon-alert-box-wrapper * {
  font-family: Arial, sans-serif;
}
#onetrust-banner-sdk div,
#onetrust-banner-sdk span,
#onetrust-banner-sdk h1,
#onetrust-banner-sdk h2,
#onetrust-banner-sdk h3,
#onetrust-banner-sdk h4,
#onetrust-banner-sdk h5,
#onetrust-banner-sdk h6,
#onetrust-banner-sdk p,
#onetrust-banner-sdk img,
#onetrust-banner-sdk svg,
#onetrust-banner-sdk button,
#onetrust-banner-sdk section,
#onetrust-banner-sdk a,
#onetrust-banner-sdk label,
#onetrust-banner-sdk input,
#onetrust-banner-sdk ul,
#onetrust-banner-sdk li,
#onetrust-banner-sdk nav,
#onetrust-banner-sdk table,
#onetrust-banner-sdk thead,
#onetrust-banner-sdk tr,
#onetrust-banner-sdk td,
#onetrust-banner-sdk tbody,
#onetrust-banner-sdk .main-content,
#onetrust-banner-sdk .toggle,
#onetrust-banner-sdk #content,
#onetrust-banner-sdk .checkbox,
#onetrust-pc-sdk div,
#onetrust-pc-sdk span,
#onetrust-pc-sdk h1,
#onetrust-pc-sdk h2,
#onetrust-pc-sdk h3,
#onetrust-pc-sdk h4,
#onetrust-pc-sdk h5,
#onetrust-pc-sdk h6,
#onetrust-pc-sdk p,
#onetrust-pc-sdk img,
#onetrust-pc-sdk svg,
#onetrust-pc-sdk button,
#onetrust-pc-sdk section,
#onetrust-pc-sdk a,
#onetrust-pc-sdk label,
#onetrust-pc-sdk input,
#onetrust-pc-sdk ul,
#onetrust-pc-sdk li,
#onetrust-pc-sdk nav,
#onetrust-pc-sdk table,
#onetrust-pc-sdk thead,
#onetrust-pc-sdk tr,
#onetrust-pc-sdk td,
#onetrust-pc-sdk tbody,
#onetrust-pc-sdk .main-content,
#onetrust-pc-sdk .toggle,
#onetrust-pc-sdk #content,
#onetrust-pc-sdk .checkbox,
#ot-sdk-cookie-policy div,
#ot-sdk-cookie-policy span,
#ot-sdk-cookie-policy h1,
#ot-sdk-cookie-policy h2,
#ot-sdk-cookie-policy h3,
#ot-sdk-cookie-policy h4,
#ot-sdk-cookie-policy h5,
#ot-sdk-cookie-policy h6,
#ot-sdk-cookie-policy p,
#ot-sdk-cookie-policy img,
#ot-sdk-cookie-policy svg,
#ot-sdk-cookie-policy button,
#ot-sdk-cookie-policy section,
#ot-sdk-cookie-policy a,
#ot-sdk-cookie-policy label,
#ot-sdk-cookie-policy input,
#ot-sdk-cookie-policy ul,
#ot-sdk-cookie-policy li,
#ot-sdk-cookie-policy nav,
#ot-sdk-cookie-policy table,
#ot-sdk-cookie-policy thead,
#ot-sdk-cookie-policy tr,
#ot-sdk-cookie-policy td,
#ot-sdk-cookie-policy tbody,
#ot-sdk-cookie-policy .main-content,
#ot-sdk-cookie-policy .toggle,
#ot-sdk-cookie-policy #content,
#ot-sdk-cookie-policy .checkbox {
  font-size: 15px !important;
  font-family: Arial, sans-serif !important;
}

#onetrust-consent-sdk #onetrust-pc-sdk .category-host-list-handler {
  font-size: 14px !important;
}
#onetrust-pc-sdk h2 {
  font-size: 25px !important;
}
@media (max-width: 767px) {
  #onetrust-pc-sdk.otPcTab #pc-title {
    font-size: 22px !important;
  }
}
