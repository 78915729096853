* {
  padding: 0;
  border: 0;
  margin: 0;
  outline: none;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

html,
body {
  width: 100%;
  -webkit-text-size-adjust: none;
}

html {
  /* set 16px default browser size to 10px for easy remming - also respects users browser default font size */
  font-size: 62.5%;
  min-height: 100%;
}

body {
  position: relative;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

div,
address,
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
main,
menu,
nav,
section,
input,
textarea,
select,
label,
img {
  display: block;
}
ul {
  list-style: none;
}
a {
  color: inherit;
  text-decoration: none;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: normal;
  font-size: inherit;
}

address {
  font-style: normal; /* default italic */
}

img {
  max-width: 100%;
  width: auto;
  height: auto;
  -ms-interpolation-mode: bicubic;
  border: none;
}

input,
textarea {
  background-color: transparent;
}
textarea {
  resize: none;
}

object {
  outline: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}

//quirky moz dotted button line
input.btn[type='text']::-moz-focus-inner,
input.btn[type='reset']::-moz-focus-inner,
input.btn[type='button']::-moz-focus-inner,
input.btn[type='submit']::-moz-focus-inner {
  border: 0;
}

input[type='text'],
input[type='reset'],
input[type='button'],
input[type='submit'] {
  line-height: normal;
}

iframe {
  border: 0 none;
}

//get those real whites
video {
  -webkit-filter: brightness(108.5%);
}

button {
  font-family: inherit;
  -webkit-appearance: none;
  min-width: 0;
  min-height: 0;
  font-size: inherit;
  background: transparent;
}
