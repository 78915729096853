.more_button {
  background-color: $color_purple;
  border-radius: 20px;
  color: #fff;
  cursor: pointer;
  display: block;
  margin: 0 auto;
  padding: 20px;
  position: relative;
  text-decoration: none;
  font-family: 'Pusab';
  -webkit-transition: background-color 0.3s;
  transition: background-color 0.3s;

  @include fontsize(30);
}

.more_button span {
  display: block;
  margin: 0;
}

.more_button .icon {
  font-family: Arial, sans-serif;
  position: absolute;
  right: 20px;
  top: 50%;

  -webkit-transition: -webkit-transform 0.3s $bounce;
  transition: transform 0.3s $bounce;

  @include translatecentery();
  @include fontsize(70);
}

.more_button__text {
  padding-right: 50px;
}

html[dir='rtl'] {
  .more_button span {
    margin-right: 20%;
  }

  .more_button .icon {
    margin-right: 0%;
  }
}

html[dir='rtl'] .more_button .icon {
  float: left;
}

.no-touchevents .more_button:hover {
  background-color: $color_purple;

  .icon {
    @include translatecentery(rotate(-20deg));
  }
}

.more_button:active,
.more_button:focus {
  background-color: $color_purple;
}
