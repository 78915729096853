/* Sort amends : using react-dropdown with different dom structure
------------------------------------------------------------------------------- */
.sort {
  float: left;
  width: 100%;
  margin-bottom: 3%;
  cursor: pointer;

  @include fontp();
}

.sort {
  .Dropdown-placeholder {
    background-color: $color_yellow;
    color: $color_purple;
    display: block;
    float: left;
    font-size: 2.3rem;
    position: relative;
    padding: 10px;
    text-align: center;
    text-decoration: none;
    width: 100%;
    z-index: 10;

    @include fontsize(20);

    &:after {
      background-repeat: no-repeat;
      background-position: center;
      background-size: 45%;
      content: '';
      height: 45px;
      right: 0;
      position: absolute;
      top: 0;
      width: 45px;

      -ms-transform: rotate(0deg);
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);

      -webkit-transition: -webkit-transform 0.3s ease-in-out;
      -ms-transition: -ms-transform 0.3s ease-in-out;
      transition: transform 0.3s ease-in-out;

      @include backgroundimage('sort_arrow_default.svg');
    }
  }
}

html[dir='rtl'] .sort .Dropdown-placeholder {
  background-position: 5% center;
  float: right;
}

.sort {
  .Dropdown-menu {
    display: none;
    float: left;
    width: 100%;
    background: #ffffff;
    padding: 2%;
    color: #ffffff;
    text-align: center;
  }
}

.sort .Dropdown-menu .Dropdown-option {
  cursor: pointer;
}

.sort.is-open .Dropdown-menu {
  display: block;
}

.sort.is-open .Dropdown-placeholder:after {
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}

.sort .Dropdown-option {
  background: none !important;
  padding: 1%;
  color: #000000;

  &:hover {
    opacity: 0.8;
  }
}

.sort .Dropdown-option:focus,
.sort .Dropdown-option:hover,
.sort .Dropdown-option.is-selected {
  color: #000000;
}

/* Media queries
------------------------------------------------------------------------------- */

@media all and (min-width: 480px) {
  .sort {
    position: relative;
    float: right;
    width: 200px;
  }

  html[dir='rtl'] .sort {
    float: left;
  }

  .sort .Dropdown-menu {
    position: absolute;
    top: 100%;
    z-index: 40;
  }

  .sort .Dropdown-menu .Dropdown-option {
    @include fontsize(20);
    padding: 5px 0;
  }
}

@media all and (min-width: 768px) {
  .sort {
    float: none;
    position: absolute;
    top: 20px;
    right: 20px;
  }

  html[dir='rtl'] .sort {
    right: auto;
    left: 20px;
  }
}
