.block-list {
  margin-bottom: 20px;

  > .wrapper {
    border-radius: 45px;

    @media (max-width: 767px) {
      border-radius: 35px;
      padding-bottom: 0;
    }
  }

  h2 {
    @include fontp();
    @include fontsize(35);
    line-height: 1;
    margin-bottom: $padding;
    width: 80%;
  }
  &.block-list-filter {
    margin-top: $sectionMargin;
    .content_item {
      margin-bottom: $padding;
    }
  }

  &.margin_top {
    margin-top: $sectionMargin;
  }
}

@media (max-width: 767px) {
  .block-list {
    h2 {
      margin: {
        top: math.div($padding, 2);
        bottom: math.div($padding, 2);
      }

      @include fontsize(28);
    }

    &.block-list-filter {
      margin-top: math.div($sectionMargin, 2);
      .content_item {
        margin-bottom: math.div($padding, 2);
      }
    }
    &.margin_top {
      margin-top: math.div($sectionMargin, 2);
    }
  }
}
