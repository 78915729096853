.caption {
  padding: $padding 0;

  @include fontsize(24);

  h1 {
    color: $color_purple;

    @include fontsize(36);
    @include fontp();
  }

  &--center {
    text-align: center;
  }

  .like_views_container {
    background-color: $color_purple;
    border-radius: 30px;
    padding-top: 5px;
    padding-right: 5px;
    padding-left: 5px;
  }
}

@media (max-width: 767px) {
  .caption {
    padding: math.div($padding, 2) 0;

    @include fontsize(18);

    h1 {
      @include fontsize(24);
    }
  }
}

.b_pink,
.b_purple {
  .caption a {
    color: $color_blue;
  }
}
