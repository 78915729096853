.like_views_container {
  float: right;
  margin-bottom: 10px;
  margin-left: 10px;
  direction: rtl;

  &--hidden {
    display: none;
  }
}
[dir='rtl'] {
  .like_views_container {
    float: left;
  }
}

.like_views_container {
  margin-bottom: 0;
}
