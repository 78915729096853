.footer_link {
  position: relative;
  display:block;
  width: 100%;
  height: auto;
  background-color: #ffffff;
  color: #000000;
  padding: 40px;
  margin-top: 1%;
  @include fontsize(32);

  .copy {
    margin-top: 28px;
    word-wrap: break-word;
    ol,
    ul {
      margin-left: 20px;
    }
    ol,
    ul,
    p {
      margin-bottom: 20px;
      word-wrap: break-word;
    }
    h2,
    h1 {
      @include fontsize(38);
      margin-bottom: 30px;
    }
  }

  a,
  a:visited,
  a:active,
  a:focus {
    color: #ec008c;
  }
}
