.hero_section {
  margin-top: 0;

  .wrapper {
    padding: 20px !important;
  }

  .hero_image {
    position: relative;

    img {
      border-radius: 25px;
    }

    .buttons {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      padding: $padding;

      .button_inner {
        border-color: #ffffff;
        border-style: solid;
        border-width: 4px;
      }

      .wrapper {
        width: 100%;
      }
    }
  }
}

@media (max-width: 767px) {
  .hero_section .hero_image .buttons {
    position: relative;
    padding: math.div($padding, 2) 0;
  }

  .hero_section .hero_image img {
    border-radius: 35px;
  }
}
