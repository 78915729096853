/*
 * Base fonts : lazy loaded
*/
@font-face {
  font-family: 'iconcomics';
  src: url('#{$font_path}iconcomics/iconcomics.woff?noqdlo') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'DinnNextMedium';
  src: url('#{$font_path_critical}DINNextRoundedLTPro-Medium.otf');
}
