video {
  z-index: 0 !important;
}
.vjs-control-bar {
  display: none;
}

/* VIDEO PLAYER */
.video_wrapper {
  position: relative;
  max-width: 100%;
  width: 100%;
  &:before {
    content: '';
    display: block;
    width: 100%;
    position: relative;
    padding-bottom: 56.25%;
    padding-top: 36px;
  }

  video,
  iframe,
  object,
  embed,
  .mejs-container,
  .video-js,
  .BrightcoveExperience {
    position: absolute !important;
    top: 0;
    left: 0;
    height: 100% !important;
    width: 100% !important;
    max-width: 100%;
    z-index: 1;
  }
  .mejs-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100% !important;
    height: 100% !important;
    max-width: 100%;
  }

  &.bitmovin {
    padding-bottom: 0 !important;
    &:before {
      display: none;
    }

    #video-placeholder {
      width: 100%;
    }
  }
}

.videoendcard {
  position: absolute !important;
  top: 0;
  left: 0;
  height: 100% !important;
  width: 100% !important;
  max-width: 100%;
  z-index: 10;
  display: none;
  background-color: $color_blue;
  padding: 20px;

  .video_endcard_inner {
    position: relative;
    display: block;
  }

  .close_button {
    border-radius: 20px;
    position: absolute;
    top: 5px;
    right: 5px;
    padding: 22px;
    background-color: $color_orange;
    cursor: pointer;
    @include backgroundimage('cross.svg');
    background-size: 70% auto;
    background-position: center;
    background-repeat: no-repeat;
    -webkit-transition: background-color 0.3s;
    transition: background-color 0.3s;
  }
  .items {
    position: relative;
    width: 100%;
    display: block;

    // Sorts out the gapping between elements
    font-size: 0;
  }
  .item {
    border-radius: 20px;
    position: relative;
    display: inline-block;
    vertical-align: top;
    width: 31.3%;
    margin: 1%;
    padding-bottom: 16%;
    color: #ffffff;
    background-color: #ffffff;
    overflow: hidden;
    &.timer {
      background-color: $color_green;
    }
    &.next_item .bg {
      -webkit-animation: nextvideofade 1.5s infinite;
      animation: nextvideofade 1.5s infinite;
    }
  }
  .bg {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 0;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    -webkit-transition: -webkit-transform 0.3s, opacity 0.3s;
    transition: transform 0.3s, opacity 0.3s;
    @include backface();
  }

  .cardtitle {
    position: relative;
    width: 100%;
    text-align: center;
    @include fontsize(30);
  }

  .autoplayoffmessage {
    @include fontsize(22);
  }

  .type_icon {
    position: absolute;
    left: 0;
    bottom: 0;
    padding: 4px;
    background-color: $color_orange;
    width: 50px;
    height: 50px;
    z-index: 2;
    img {
      position: absolute;
      display: inline-block;
      top: 50%;
      left: 50%;
      @include translatecenter();
    }
  }

  .title {
    position: absolute;
    width: 100%;
    bottom: 0;
    left: 0;
    opacity: 0;
    -webkit-transition: opacity 0.3s;
    transition: opacity 0.3s;
    height: 50px;
    background-color: rgba(0, 0, 0, 0.3);
    z-index: 2;
    span {
      position: absolute;
      width: 100%;
      padding: 4px;
      padding-left: 54px; /* .type_icon width + padding*/
      top: 50%;
      left: 0;
      @include translatecentery();
      @include fontsize(22);
    }
  }

  .item_timer {
    position: absolute;
    display: inline-block;
    top: 50%;
    left: 50%;
    width: 95%;
    @include translatecenter();
    text-align: center;
  }

  .timer_title {
    @include fontsize(22);
    line-height: 1;
    display: block;
  }
  .number {
    @include fontsize(60);
    line-height: 1;
    display: block;
    @include fontb();
  }
  .interval {
    @include fontsize(22);
    line-height: 1;
    display: block;
    @include fontb();
  }
}

.no-touchevents {
  .videoendcard {
    .close_button:hover {
      background-color: lighten($color_orange, 10%);
    }
    .item:hover {
      .title {
        opacity: 1;
      }
      .bg {
        @include transform(scale(1.1));
      }
    }
  }
}

@-webkit-keyframes nextvideofade {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}
@keyframes nextvideofade {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}

@media (max-width: 767px) {
  .videoendcard {
    padding: 5px;
    .items {
      width: 96%;
      margin: 0 auto;
    }
    .close_button {
      padding: 16px;
    }
  }

  .videoendcard .item {
    width: 48%;
    padding-bottom: 24%;
    display: none;
  }
  .videoendcard .item.timer,
  .videoendcard .item:nth-child(1),
  .videoendcard .item:nth-child(2),
  .videoendcard .item:nth-child(3) {
    display: inline-block;
  }
  .videoendcard .cardtitle {
    @include fontsize(24);
  }
  .videoendcard .title {
    height: 30px;
  }
  .videoendcard .title span {
    padding-left: 34px;
    @include fontsize(14);
  }
  .videoendcard .type_icon {
    height: 30px;
    width: 30px;
  }
  .videoendcard .number {
    @include fontsize(40);
    line-height: 0.8;
  }
  .videoendcard .timer_title {
    @include fontsize(16);
    line-height: 1;
  }
  .videoendcard .interval {
    @include fontsize(16);
    line-height: 1;
  }
}

.showendcard .videoendcard {
  display: block;
}
.showendcard video,
.showendcard iframe,
.showendcard object,
.showendcard embed,
.showendcard .mejs-container,
.showendcard .video-js,
.showendcard .BrightcoveExperience {
  visibility: hidden;
  opacity: 0;
  z-index: -9999;
}
/* VIDEO PLAYER */
