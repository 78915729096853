.flash-message{
	position: relative;
    padding: 100px 20px;
    background-color: rgba(0, 0, 0, 0.1);
	&__title{
		text-align: center;
		text-transform: uppercase;
		@include fontsize(50);
		@include fontb();
		padding: $sectionMargin;
		display: block;
		margin: 0 auto;
	}
}

@media(max-width: 767px){
.flash-message{
	&__title{
		@include fontsize(30);
		padding: math.div($sectionMargin, 2);
	}
}
}
