$image_path: '../images/';
$image_path_critical: '../images/critical/';
$font_path: '../fonts/';
$font_path_critical: '../fonts/critical/';

$color_facebook: #3b5998;
$color_twitter: #00b0ed;
$color_linkedin: #0065a0;

// Colors override
$color_white: #fff;
$color_blue: #38d1db;
$color_yellow: #f7e003;
$color_orange: #ff634a;
$color_green: #5ced6e;
$color_purple: #400a36;
$iconHeight: 48px;

// cartoonito : darken($color_blue, 5%);
// boing : #FF9BCF;
$color_lc_base: #FF9BCF;
$color_lc_highlight: #1E1A4D;
$color_lc_text: #F8F4D5;
$color_lc_input: rgba(255, 155, 207, 0.2);

$bounce: cubic-bezier(0.47, 2.02, 0.31, -0.36);
$mildbounce: cubic-bezier(0.47, 3.32, 0.31, -0.36);
$superbounce: cubic-bezier(0.47, 5.02, 0.31, -0.36);

$padding: 20px;
$sectionMargin: 20px;
$iconHeight: 60px;

$labelColor: rgba(0, 0, 0, 0.3);

// $color_blue: #43b8e7;
// $color_yellow: #f9eb46;
// $color_purple: #853a95;
$color_pink: #e62d91;
